/*
 * (C) Copyright  2024 DigitalLab.  Confidential computer software.
 * Valid license from DigitalLab required for possession, use or copying.
 * Consistent with FAR 12.211 and 12.212, Commercial Computer Software,
 * Computer Software Documentation, and Technical Data for Commercial Items are
 * licensed to the U.S. Government under vendor's standard commercial license.
 */

import { ChatListItem } from './ChatListItem';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

/**
 * Chat list component, this is where the list of past chats should be displayed.
 * @param chatsList list of chats
 * @param isLoading if the chat list is loading
 * @returns {JSX.Element} chat list
 * @constructor
 */
export const ChatList = ({ chatsList, isLoading, handleMenu }) => {
  const navigate = useNavigate();
  return (
    <div className="menu-list-wrapper">
      <div className="menu-list-title">
        <h3 className="previous-chat-list-title"> <button className="menu-button" onClick={handleMenu}><span className="material-symbols-outlined">arrow_menu_close</span></button> Previous chats</h3>
        <span onClick={() => navigate('/')} className="material-symbols-outlined">
          add_comment
        </span>
      </div>
      {isLoading ? (
        <div className="loader-wrapper">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <>
          {chatsList.map((chat) => {
            return <ChatListItem key={chat.id} chat={chat}></ChatListItem>;
          })}
        </>
      )}
    </div>
  );
};

ChatList.propTypes = {
  chatsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool,
    handleMenu: PropTypes.func,
};
